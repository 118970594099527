import React, { useEffect } from 'react';

function FillChange({ onColorChange }) {
  const applyFillChanges = async () => {
    try {
      const countryLevel = document.getElementById('countryLevel').value;
      const statisticsType = document.getElementById('statisticsName').value;
      const svgdoc = document.querySelector('#mainView').contentDocument;
      const pathElements = svgdoc.querySelector('#layers').querySelectorAll('path');
      document.getElementById('squaresContainer')?.remove();

      if (countryLevel === '1') {
        const colorList = [];
        function padLeft(str, len) {
          return str.padStart(len, '0');
        }

        function rgbToHex(r, g, b) {
          return '#' + padLeft(r.toString(16), 2) + padLeft(g.toString(16), 2) + padLeft(b.toString(16), 2);
        }

        for (let r = 0; r < 256; r += 32) {
          for (let g = 0; g < 256; g += 32) {
            for (let b = 0; b < 256; b += 32) {
              colorList.push(rgbToHex(r, g, b));
            }
          }
        }

        const shuffledArray = colorList.sort(() => Math.random() - 0.5);

        for (let i = 0; i < 262; i++) {
          const number = parseInt(i + 1000001);
          document.querySelector('#mainView').contentDocument.getElementById(parseInt(number)).style.fill = shuffledArray[i];
        }
      } else if (countryLevel === '2') {
        for (let i = 0; i < pathElements.length; i++) {
          const pathElement = pathElements[i];
          pathElement.removeAttribute('style');
          pathElement.setAttribute('fill', `#B9B9B9`);
          pathElement.setAttribute('stroke', `#ffffff`);
          pathElement.setAttribute('stroke-width', `0.1px`);
        }
      } else if (countryLevel === '4') {
        if (statisticsType === '2') {
          const cacheKey = `ranking`;
          const cachedData = sessionStorage.getItem(cacheKey);

          if (cachedData) {
            console.log('Using cached data');
            applyRankingData(JSON.parse(cachedData), pathElements);
          } else {
            console.log('Fetching new data');
            async function getranking() {
              try {
                const response = await fetch('https://statesplan.com/request/ranking');
                const data = await response.json();
                sessionStorage.setItem(cacheKey, JSON.stringify(data));
                applyRankingData(data, pathElements);
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            }
            getranking();
          }
        } else if (statisticsType === '1') {
          for (let i = 0; i < pathElements.length; i++) {
            const pathElement = pathElements[i];
            const pathId = pathElement.getAttribute('id');
            console.log(pathId);

            const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
            const pattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
            pattern.setAttribute('id', `pattern-${pathId}`);
            pattern.setAttribute('width', '1');
            pattern.setAttribute('height', '1');
            pattern.setAttribute('patternUnits', 'objectBoundingBox');

            // Check if the image URL is cached
            const cachedImageUrl = sessionStorage.getItem(`image-${pathId}`);
            if (cachedImageUrl) {
              console.log(`Using cached image for ${pathId}`);
              setImageAttributes(pattern, cachedImageUrl, pathElement, pathElements[i]);
            } else {
              const imageUrl = `https://statesplan.com/images/${pathId}.svg`;
              fetch(imageUrl)
                .then(response => response.blob())
                .then(blob => {
                  const imageUrl = URL.createObjectURL(blob);
                  sessionStorage.setItem(`image-${pathId}`, imageUrl);
                  setImageAttributes(pattern, imageUrl, pathElement, pathElements[i]);
                })
                .catch(error => console.error(`Error fetching image for ${pathId}:`, error));
            }

            defs.appendChild(pattern);
            pathElement.closest('svg').appendChild(defs);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const setImageAttributes = (pattern, imageUrl, pathElement, pathElementOriginal) => {
    const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    image.setAttribute('href', imageUrl);
    image.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    image.setAttribute('id', `imgs${pathElementOriginal.getAttribute('id')}`);
    pattern.appendChild(image);
    pathElement.removeAttribute('style');
    pathElement.setAttribute('fill', `url(#pattern-${pathElementOriginal.getAttribute('id')})`);
    const bbox = pathElement.getBBox();
    let width = bbox.width;
    let height = bbox.height;
    if (pathElementOriginal.id === '1000197') {
    }
    if (pathElementOriginal.id === '1000242') {
      width = 600;
      height = 200;
    }
    image.setAttribute("width", width);
    image.setAttribute("height", height);
  };

  const applyRankingData = (data, pathElements) => {
    data.forEach(item => {
      const fid = item.fid;
      const result = item.result;

      pathElements.forEach(path => {
        if (path.id === fid) {
          path.setAttribute('data-ranking', result);
        } else {
          if (!path.getAttribute('data-ranking')) {
            path.setAttribute('data-ranking', 'NULL');
          }
        }
      });
    });

    const rootElement = document.getElementById('mainViewContainer');
    if (rootElement) {
      const gradientRect = document.createElement('div');
      gradientRect.id = 'gradientRect';
      Object.assign(gradientRect.style, {
        position: 'fixed',
        left: '34vh',
        top: '4vh',
        width: '3vh',
        height: '27vh',
        border: '1px solid #000',
        display: 'none',
        background: 'linear-gradient(to bottom, var(--color-a), var(--color-b))'
      });

      const squaresContainer = document.createElement('div');
      squaresContainer.id = 'squaresContainer';
      Object.assign(squaresContainer.style, {
        position: 'fixed',
        left: '34vh',
        top: '8vh',
        display: 'flex',
        border: '1px solid #000',
        flexDirection: 'column'
      });

      rootElement.appendChild(squaresContainer);
      rootElement.appendChild(gradientRect);

      function generateGradientSquares(a, b, n) {
        const container = document.getElementById('squaresContainer');
        const stepR = (parseInt(b.slice(1, 3), 16) - parseInt(a.slice(1, 3), 16)) / n;
        const stepG = (parseInt(b.slice(3, 5), 16) - parseInt(a.slice(3, 5), 16)) / n;
        const stepB = (parseInt(b.slice(5), 16) - parseInt(a.slice(5), 16)) / n;
        const colorArray = [];
        colorArray.push(`rgb(${128}, ${128}, ${128})`);
        const rankingArray = ['NULL', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
        const rankingdescribe = [' = no data', ' = MinimumPower < 0.01', ' = MinorPower >= 0.01',
          ' = SmallPower >= 0.05', ' = MiddlePower >= 0.1',
          ' = ReigionalPower >= 0.5', ' = MajorPower >= 1', ' = GreatPower >= 5'];

        for (let i = 0; i < n - 1; i++) {
          const red = parseInt(a.slice(1, 3), 16) + Math.round(stepR * i);
          const green = parseInt(a.slice(3, 5), 16) + Math.round(stepG * i);
          const blue = parseInt(a.slice(5), 16) + Math.round(stepB * i);
          const color = `rgb(${red}, ${green}, ${blue})`;
          colorArray.push(color);
          const square = document.createElement('div');
          square.classList.add('square');
          square.style.backgroundColor = color;
          var textSpan = document.createElement('span');
          textSpan.textContent = rankingArray[i + 1] + rankingdescribe[i + 1];
          textSpan.style.marginLeft = '';
          square.appendChild(textSpan);
          Object.assign(square.style, {
            height: '3vh',
            border: '1px solid #000',
          });
          container.appendChild(square);
        }
        var squares = document.querySelectorAll('.square');
        var lastSquare = squares[squares.length - 1];
        if (lastSquare) {
          var cloneSquare = lastSquare.cloneNode(true);
          cloneSquare.style.backgroundColor = `rgb(${128}, ${128}, ${128})`;
          cloneSquare.querySelector('span').textContent = rankingArray[0] + rankingdescribe[0];
          lastSquare.parentNode.appendChild(cloneSquare);
        }

        pathElements.forEach(path => {
          const value = path.getAttribute('data-ranking');
          rankingArray.forEach((rank, index) => {
            if (value === rank) {
              console.log(value, index);
              path.removeAttribute('style');
              path.setAttribute('fill', colorArray[index]);
            }
          });
        });
        console.log(colorArray);
      }

      function applyGradientToRect(a, b) {
        const gradientRect = document.getElementById('gradientRect');
        gradientRect.style.setProperty('--color-a', a);
        gradientRect.style.setProperty('--color-b', b);
      }

      const aColor = '#ffffff';
      const bColorOptions = ['#400000', '#400d00', '#401a00', '#402600', '#403300', '#3b4000', '#2e4000', '#204000', '#134000', '#054000', '#00400e', '#00401b', '#004027', '#004034', '#003c40', '#002f40', '#002240', '#001540', '#000840', '#000040', '#0d0040', '#1a0040', '#260040', '#330040'];
      const bColor = bColorOptions[Math.floor(Math.random() * bColorOptions.length)];
      const squareCount = 8;

      generateGradientSquares(aColor, bColor, squareCount);
      applyGradientToRect(aColor, bColor);

    } else {
      console.error('Element with id "root" not found.');
    }
  };

  useEffect(() => {
    const countryLevelSelect = document.getElementById('countryLevel');
    const statisticsNameSelect = document.getElementById('statisticsName');

    const handleChange = () => {
      applyFillChanges();
    };

    countryLevelSelect.addEventListener('change', handleChange);
    statisticsNameSelect.addEventListener('change', handleChange);

    return () => {
      countryLevelSelect.removeEventListener('change', handleChange);
      statisticsNameSelect.removeEventListener('change', handleChange);
    };
  }, []);

  return (
    <>
      <select id="countryLevel">
        <option value="1">random</option>
        <option value="2">wikipedia style</option>
        <option value="3">geopolitics</option>
        <option value="4" selected>statistics-Value</option>
      </select>
    </>
  );
}

export default FillChange;
