import React, { useEffect } from 'react';

export default function MiniMapToggle({ miniMapChecked, setMiniMapChecked }) {
  const handleCheckboxChange = () => {
    setMiniMapChecked(!miniMapChecked);
  };

  useEffect(() => {
    const objectElement = document.getElementById('mainView');
    const thumbViewContainer = document.getElementById('thumbViewContainer');

    const attachRectClickEvent = () => {
      if (objectElement) {
        const svgDoc = objectElement.contentDocument;
        const rect = svgDoc?.getElementById('svg-pan-zoom-reset-pan-zoom');
        if (rect) {
          // Add event listeners only once
          if (!rect.getAttribute('data-listener-attached')) {
            rect.setAttribute('data-listener-attached', 'true');
            rect.addEventListener('click', () => { /* reset logic here if needed */ });
            rect.addEventListener('touchstart', () => { /* reset logic here if needed */ });
          }
        } else {
          // Retry until the rect element is found
          setTimeout(attachRectClickEvent, 100);
        }
      }
    };

    const handleObjectLoad = () => {
      attachRectClickEvent();
    };

    if (objectElement) {
      objectElement.addEventListener('load', handleObjectLoad);

      // Check if the object is already loaded
      if (objectElement.contentDocument) {
        attachRectClickEvent();
      }
    }

    // Update thumbViewContainer display based on checkbox state
    if (thumbViewContainer) {
      thumbViewContainer.style.display = miniMapChecked ? 'none' : 'block';
      document.getElementById("map-coordinate").style.display = miniMapChecked ? 'none' : 'block';
    }

    return () => {
      if (objectElement) {
        objectElement.removeEventListener('load', handleObjectLoad);
      }
    };
  }, [miniMapChecked]);

  return (
    <span>
      <input type="checkbox" id="miniMap" name="miniMap" checked={miniMapChecked} onChange={handleCheckboxChange} />
      <i>miniMap</i>
    </span>
  );
}
