// observer.js
export function setupObserver(fetchingmap, countryName, setFlagurl) {
    let observer = new IntersectionObserver((entries) => {
        entries.forEach((path) => {
            if (document.getElementById('Autoscaling').checked && path.isIntersecting) {
                if (parseFloat(document.getElementById('scaleF').textContent) > 2) {
                    if (Math.round(path.intersectionRect.width * path.intersectionRect.height) > 40000) {
                        if (!path.target.classList.contains('on')) {
                            console.log('scale:' + document.getElementById('scaleF').textContent,
                                Math.round(path.intersectionRatio * 1000) / 1000, path.target.getAttribute('id') + 'in',
                                Math.round(path.intersectionRect.width * path.intersectionRect.height));
                            fetchingmap(path.target);
                        }
                        path.target.classList.add('on');
                    }
                }
            } else if (!path.isIntersecting) {
                path.target.classList.add('out');
                path.target.classList.remove('in');
                if (path.target.id > 2000000) {
                    var s = document.getElementsByClassName(path.target.classList.item(0), 'in');
                    if (s.length === 1) {
                        console.log(path.target.classList.item(0) + 'remove required' + document.getElementsByClassName(path.target.classList.item(0)).length);
                    }
                }
            }
        });
    }, { threshold: Array.from({ length: 101 }, (_, i) => i * 0.01) });

    window.addEventListener('load', function () {
        var world = document.querySelector('#mainView').contentDocument;
        world.getElementById('svg-pan-zoom-controls').style.display = 'none';

        world.querySelectorAll('#worldmap path').forEach((path) => {
            observer.observe(path);
            path.classList.add("in");
        });

        world.addEventListener('mousedown', clicks);
        world.addEventListener('mousemove', function (event) {
            const targetElement = event.target;
            if (targetElement.tagName === 'path' || targetElement.tagName === 'text') {
                if (targetElement.id !== oldid) {
                    countryName(targetElement.id);
                    const k = './images/' + targetElement.id + '.svg';
                    setFlagurl(k);
                    world.querySelectorAll('.hovered').forEach(function (v) {
                        v.classList.remove('hovered');
                    });
                    targetElement.classList.add('hovered');
                }
                oldid = targetElement.id;
            } else {
                setFlagurl('./images/1000000.svg');
                countryName(1000000);
            }
        });

        world.addEventListener('mouseup', outs);
        world.addEventListener('wheel', function () {
            b = true;
        });
        world.addEventListener('scroll', function () {
            b = true;
        });
        world.addEventListener('click', function (event) {
            if (event.target.tagName === 'path' || event.target.tagName === 'text')
                fetchingmap(event.target);
        });
    });

    var oldid = false;
    var b = false;

    function clicks() {
        b = true;
    }
    function outs() {
        if (b) b = false;
    }
}
