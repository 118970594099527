import React, { useState, useEffect } from 'react';

export default function GridToggle({ showGrid, setShowGrid }) {
  useEffect(() => {
    const objectElement = document.getElementById('mainView');
    if (objectElement) {
      objectElement.addEventListener('load', () => {
        const svgDoc = objectElement.contentDocument;
        const svgElement = svgDoc.getElementById('worldmap');
        const viewport = svgDoc.getElementById('layers');

        if (svgElement && viewport) {
          const bbox = viewport.getBBox();
          const gridSize = 10; // Size of each grid square
          const gridGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          gridGroup.setAttribute('id', 'gridGroup');

          const gridPattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
          gridPattern.setAttribute('id', 'smallGrid');
          gridPattern.setAttribute('width', gridSize);
          gridPattern.setAttribute('height', gridSize);
          gridPattern.setAttribute('patternUnits', 'userSpaceOnUse');

          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          path.setAttribute('d', `M ${gridSize} 0 L 0 0 0 ${gridSize}`);
          path.setAttribute('fill', 'none');
          path.setAttribute('stroke', 'gray');
          path.setAttribute('stroke-width', '0.5');
          gridPattern.appendChild(path);

          const defs = svgElement.querySelector('defs') || document.createElementNS('http://www.w3.org/2000/svg', 'defs');
          defs.appendChild(gridPattern);
          svgElement.insertBefore(defs, svgElement.firstChild);

          const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          rect.setAttribute('x', bbox.x);
          rect.setAttribute('y', bbox.y);
          rect.setAttribute('width', bbox.width);
          rect.setAttribute('height', bbox.height);
          rect.setAttribute('fill', 'url(#smallGrid)');
          gridGroup.appendChild(rect);

          let counter = 1;
          const fontSize = 1; // Smaller font size

          for (let y = bbox.y; y < bbox.y + bbox.height; y += gridSize) {
            for (let x = bbox.x; x < bbox.x + bbox.width; x += gridSize) {
              const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
              text.setAttribute('x', x + gridSize / 2 - 1);
              text.setAttribute('y', y + gridSize / 2 + 6);
              text.setAttribute('text-anchor', 'middle');
              text.setAttribute('dominant-baseline', 'middle');
              text.setAttribute('class', 'gridRect');

              text.setAttribute('font-size', fontSize);
              text.setAttribute('fill', 'black');
              text.textContent = counter++;
              gridGroup.appendChild(text);
            }
          }

          viewport.insertBefore(gridGroup, viewport.firstChild);
          gridGroup.style.display = showGrid ? 'block' : 'none';
        }
      });
    }
  }, []);

  useEffect(() => {
    const gridGroup = document.querySelector('#mainView').contentDocument.getElementById('gridGroup');
    if (gridGroup) {
      gridGroup.style.display = showGrid ? 'block' : 'none';
    }
  }, [showGrid]);

  return (
    <span>
      <input type="checkbox" id="grid" name="grid" checked={showGrid} onChange={() => setShowGrid(!showGrid)} />
      <i>grid</i>
    </span>
  );
}
