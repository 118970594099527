import React from 'react';
import '../css/Controls.css'; // 스타일 파일을 import

// 버튼 컴포넌트 정의
const Button1 = ({ onClick }) => (
  <button className="control-button Button1 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      account_balance
    </span>
  </button>
);

const Button2 = ({ onClick }) => (
  <button className="control-button Button2 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      shield
    </span>
  </button>
);

const Button3 = ({ onClick }) => (
  <button className="control-button Button3 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      local_police
    </span>
  </button>
);

const Button4 = ({ onClick }) => (
  <button className="control-button Button4 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      factory
    </span>
  </button>
);

const Button5 = ({ onClick }) => (
  <button className="control-button Button5 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      energy_savings_leaf
    </span>
  </button>
);

const Button6 = ({ onClick }) => (
  <button className="control-button Button6 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      real_estate_agent
    </span>
  </button>
);

const Button7 = ({ onClick }) => (
  <button className="control-button Button7 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      health_and_safety
    </span>
  </button>
);

const Button8 = ({ onClick }) => (
  <button className="control-button Button8 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      surfing
    </span>
  </button>
);

const Button9 = ({ onClick }) => (
  <button className="control-button Button9 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      school
    </span>
  </button>
);

const Button10 = ({ onClick }) => (
  <button className="control-button Button10 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      diversity_1
    </span>
  </button>
);

const Button11 = ({ onClick }) => (
  <button className="control-button Button11 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      public
    </span>
  </button>
);

const Button12 = ({ onClick }) => (
  <button className="control-button Button12 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      lan
    </span>
  </button>
);

const Button13 = ({ onClick }) => (
  <button className="control-button Button13 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      newspaper
    </span>
  </button>
);

const Button14 = ({ onClick }) => (
  <button className="control-button Button14 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      hub
    </span>
  </button>
);

const Button15 = ({ onClick }) => (
  <button className="control-button Button15 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      help
    </span>
  </button>
);

const Button16 = ({ onClick }) => (
  <button className="control-button Button16 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      settings
    </span>
  </button>
);

const Button17 = ({ onClick }) => (
  <button className="control-button Button17 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      passkey
    </span>
  </button>
);

const Button18 = ({ onClick }) => (
  <button className="control-button Button18 icon" onClick={onClick}>
    <span className="material-symbols-outlined">
      fullscreen_exit
    </span>
  </button>
);

// 전체 컴포넌트
const Controls = () => {
  const effect1 = () => {
    console.log('Button1 클릭됨');
  };

  const effect2 = () => {
    console.log('Button2 클릭됨');
  };

  const effect3 = () => {
    console.log('Button3 클릭됨');
  };

  const effect4 = () => {
    console.log('Button4 클릭됨');
  };

  const effect5 = () => {
    console.log('Button5 클릭됨');
  };

  const effect6 = () => {
    console.log('Button6 클릭됨');
  };

  const effect7 = () => {
    console.log('Button7 클릭됨');
  };

  const effect8 = () => {
    console.log('Button8 클릭됨');
  };

  const effect9 = () => {
    console.log('Button9 클릭됨');
  };

  const effect10 = () => {
    console.log('Button10 클릭됨');
  };

  const effect11 = () => {
    console.log('Button11 클릭됨');
  };

  const effect12 = () => {
    console.log('Button12 클릭됨');
  };

  const effect13 = () => {
    console.log('Button13 클릭됨');
  };

  const effect14 = () => {
    console.log('Button14 클릭됨');
  };

  const effect15 = () => {
    console.log('Button15 클릭됨');
  };

  const effect16 = () => {
    console.log('Button16 클릭됨');
  };

  const effect17 = () => {
    console.log('Button17 클릭됨');
  };

  const effect18 = () => {
    // 전체 화면 모드 전환
    const elem = document.documentElement;
    const countryFrame = document.getElementById('country-frame');
  
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) { // IE/Edge
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }
  
    // country-frame 요소 크기 및 위치 조정
  };
  

  return (
    <div className="controls-container">
      <Button1 onClick={effect1} />
      <Button2 onClick={effect2} />
      <Button3 onClick={effect3} />
      <Button4 onClick={effect4} />
      <Button5 onClick={effect5} />
      <Button6 onClick={effect6} />
      <Button7 onClick={effect7} />
      <Button8 onClick={effect8} />
      <Button9 onClick={effect9} />
      <Button10 onClick={effect10} />
      <Button11 onClick={effect11} />
      <Button12 onClick={effect12} />
      <Button13 onClick={effect13} />
      <Button14 onClick={effect14} />
      <Button15 onClick={effect15} />
      <Button16 onClick={effect16} />
      <Button17 onClick={effect17} />
      <Button18 onClick={effect18} />
    </div>
  );
};

export default Controls;
