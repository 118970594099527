import './css/App.css';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import Heart from './js/heart.js';
import { Footer } from './js/Footer';
import { BottomSection } from './js/BottomSection';
import { RightSection } from './js/RightSection';
import { Timing } from './js/Timing';
import { Header } from './js/Header';
import { FlagFigure } from './js/flagfigure';
import Controls from './js/Controls';
import { setupObserver } from './js/observer'; // Import the observer setup function

const cache = {};

export default function App() {
    const [selected_country, countryName] = useState(1000000);
    const [current, setCurrent] = useState(new Date());
    const [flagurl, setFlagurl] = useState('./images/1000000.svg');
    const click = useRef('ClickFalse');
    let classType = 'B';
    let strokeClass = 0.05;

    const [dateConfigKey, setDateConfigKey] = useState('X'); // 기본값으로 'a' 설정

    const fetchingmap = useCallback(async function logJSONData(parentpath) {
        const w = await parentpath.id;
        const path2 = `./query/query?id=${w}`;
        
        const cachedData = cache[path2] || JSON.parse(localStorage.getItem(path2));
        if (cachedData) {
            console.log('Using cached data for:', path2);
            processMapData(cachedData, parentpath);
        } else {
            const response2 = await fetch(path2);
            const text2 = await response2.json();
            cache[path2] = text2;
            localStorage.setItem(path2, JSON.stringify(text2));
            processMapData(text2, parentpath);
        }
    }, []);

    const processMapData = async (text2, parentpath) => {
        if (text2.startnum) {
            console.log(text2.startnum + '~' + text2.endnum + ' from ' + parentpath.id);
            if (text2.startnum > 2000000 && text2.startnum < 3000000) {
                classType = 'B';
                strokeClass = 0.1;
            } else if (text2.startnum > 3000000 && text2.startnum < 4000000) {
                classType = 'C';
                strokeClass = 0.05;
            } else if (text2.startnum > 4000000 && text2.startnum < 5000000) {
                classType = 'D';
                strokeClass = 0.025;
            } else if (text2.startnum > 6000000 && text2.endnum < 7000000) {
                classType = 'E';
                strokeClass = 0.01;
            }

            const reqParse = async (link) => {
                const resText = await fetch(link).then((response) => response.text());
                const resTextxml = new DOMParser().parseFromString(resText, 'text/html');
                return resTextxml.body.firstChild.firstChild;
            };

            const arr = [];
            for (let i = text2.startnum; i <= text2.endnum; i++) {
                arr.push(i);
            }

            for (const i of arr) {
                const render = async () => {
                    const getSVGPath = `./class${classType}/layer/${i}.svg`;
                    const getSVGText = `./class${classType}/txt/${i}.svg`;

                    const SVGPath = await reqParse(getSVGPath);
                    const SVGText = await reqParse(getSVGText);

                    if (!SVGPath) {
                        render();
                        return;
                    }

                    const newpath = document.createElementNS("http://www.w3.org/2000/svg", "path");
                    newpath.setAttribute("id", SVGPath.id);
                    newpath.setAttribute("d", SVGPath.getAttribute("d"));
                    newpath.setAttribute("style", `stroke:white; fill:${parentpath.style.fill}; stroke-width:0;`);
                    newpath.setAttribute("class", SVGPath.getAttribute("class"));
                    parentpath.parentElement.append(newpath);
                    newpath.classList.add("in");

                    const newtext = document.createElementNS("http://www.w3.org/2000/svg", "text");
                    newtext.setAttribute("id", SVGText.id);
                    newtext.setAttribute("transform", SVGText.getAttribute("transform"));
                    newtext.setAttribute("class", SVGText.getAttribute("class"));
                    newtext.setAttribute("style", "fill:green; font-family:sans-serif; font-size:5; text-anchor:middle;");
                    newtext.textContent = SVGText.textContent;
                    parentpath.parentElement.append(newtext);
                    parentpath.style.display = "none";
                };
                render();
            }
            console.log(text2.startnum + '~' + text2.endnum + ' is observing');
        }
    };

    window.fetchingmap = fetchingmap;

    useEffect(() => {
        setupObserver(fetchingmap, countryName, setFlagurl); // Setup observer with fetchingmap, countryName, setFlagurl
    }, [fetchingmap]);

    return (
        <>
            <main style={{ overflow: 'hidden' }}>
                <FlagFigure />
                <Controls />
                <Header onButtonSelect={setDateConfigKey} />
                <Timing current={current} setCurrent={setCurrent} configKey={dateConfigKey} />
                <BottomSection />
                <RightSection current={current} country={selected_country} president={flagurl} flagClick={click} />
                <Heart />
                <Footer />
            </main>
        </>
    );
}
