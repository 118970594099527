import React, {useRef} from "react";
import '../css/Heart.css';
export default function Heart() {

		 window.onload = function() {
			
			const svgdoc = document.querySelector('#mainView').contentDocument;
			const pathElements = svgdoc.querySelector('#layers').querySelectorAll('path');
			for (let i = 0; i < pathElements.length; i++) {
				const pathElement = pathElements[i];
				const pathId = pathElement.getAttribute('id');
				console.log(pathId); // 각 path의 id를 console에 표시

				// <defs> 요소 생성
				const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
				const pattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
				pattern.setAttribute('id', `pattern-${pathId}`);
				pattern.setAttribute('width', '1');
				pattern.setAttribute('height', '1');
				pattern.setAttribute('patternUnits', 'objectBoundingBox');
				const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
				image.setAttribute('href', `https://statesplan.com/images/${pathId}.svg`);
				image.setAttribute('preserveAspectRatio', 'xMidYMid meet');
				image.setAttribute('id', `imgs${pathId}`);

				// <pattern> 요소에 <image> 요소 추가
				pattern.appendChild(image);

				// <defs> 요소에 <pattern> 요소 추가
				defs.appendChild(pattern);

				// SVG에 <defs> 요소 추가
				pathElement.closest('svg').appendChild(defs);

				// path 태그에 fill 속성 추가
				pathElement.removeAttribute('style');
				pathElement.setAttribute('fill', `url(#pattern-${pathId})`);

				// path의 크기 가져오기
				const bbox = pathElement.getBBox();
				let width = bbox.width;
				let height = bbox.height;
				if(pathElements[i].id==='1000197')
				{
				}
				if(pathElements[i].id==='1000242')
				{
					width=600;
					height=200;	
				}
				// 이미지 요소에 크기 설정
				image.setAttribute("width", width);
				image.setAttribute("height", height);
			}
		 };
		 

  return (
<>
</>

  );
}