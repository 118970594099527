import {useEffect, useState} from 'react';
import '../App.js';
import './heart.js';
import '../css/Footer.css';
export default function Footer(){
		const [myCountry, setmyCountry]=useState('undefined');
		const [myState, setmyState]=useState('undefined');
		const [myCity, setmyCity]=useState('undefined');
		
		async function getip()
		{
			const ipData = await fetch('https://geolocation-db.com/json/');
			const locationIp = await ipData.json();
			setmyCountry(locationIp.country_name);
			setmyState(locationIp.state);
			setmyCity(locationIp.city);
		}
		useEffect(()=>
		{	
			getip();
		},[]);


		
  return (
			<>				
				<footer>
					<aside>
						<span>
							Permission=(Lv.1)&emsp;&emsp;
						</span>
						<span>
							ClinetNum=undefined&emsp;&emsp;
							Client=&#91;{myCountry}, {myState}, {myCity}&#93;
						</span>
					</aside>
					<address>

						<span>
							&copy;2000-2024 YCJ&emsp;|
							&emsp;<a href="https://statesplan.com/privacy_policy">contact & privacy_policy</a>&emsp;
						</span>
					</address>
				</footer>
				
			</>
		);
}
export {Footer};
