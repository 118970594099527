import React, { useState, useEffect } from 'react';

export default function LabelTextSize({ initialFontSize, setInitialFontSize }) {
  useEffect(() => {
    const fontSizeSelector = document.getElementById('fontS');
    const objectElement = document.getElementById('mainView');
    const countryFrameElement = document.getElementById('country-frame');

    const updateFontSize = () => {
      const value = fontSizeSelector.value;
      let scaleFactor;
      switch (value) {
        case '1':
          scaleFactor = 0.5;
          break;
        case '2':
          scaleFactor = 1.0;
          break;
        case '3':
          scaleFactor = 1.5;
          break;
        case '4':
          scaleFactor = 2.0;
          break;
        case '5':
          scaleFactor = 2.5;
          break;
        case '6':
          scaleFactor = parseFloat(prompt('input your scale value (default=1)'));
          break;
        default:
          scaleFactor = 1.0;
      }

      const updateTextElementsFontSize = (svgDoc) => {
        if (svgDoc) {
          const textElements = svgDoc.querySelectorAll('text:not(.gridRect)');
          resizing(textElements);
        } else {
          const textElements = document.getElementById('country-frame').querySelectorAll('text:not(.gridRect)');
          resizing(textElements);
        }
      };
      const resizing = (textElements) => {
        textElements.forEach((textElement) => {
          if (initialFontSize === null) {
            const currentFontSize = window.getComputedStyle(textElement).fontSize;
            setInitialFontSize(parseFloat(currentFontSize));
          }
          textElement.style.fontSize = `${initialFontSize * scaleFactor}px`;
        });
      }
      if (objectElement) {
        const svgDoc = objectElement.contentDocument;
        updateTextElementsFontSize(svgDoc);
      }

      if (countryFrameElement) {
        const countryFrameDoc = countryFrameElement.contentDocument;
        updateTextElementsFontSize(countryFrameDoc);
      }
    };

    updateFontSize();
    fontSizeSelector.addEventListener('change', updateFontSize);
    return () => {
      fontSizeSelector.removeEventListener('change', updateFontSize);
    };
  }, [initialFontSize, setInitialFontSize]);

  return (
    <span>
      <i>label Text Size</i>
      <select id="fontS">
        <option value="1">TinySmall</option>
        <option value="2" selected>Small</option>
        <option value="3">Medium</option>
        <option value="4">Large</option>
        <option value="5">SuperLarge</option>
        <option value="6" disabled>custom(premium)</option>
      </select>
    </span>
  );
}
