import React, { useState } from 'react';
import '../css/BottomSection.css';
import FillChange from './ChangeFill.js';
import GridToggle from './GridToggle.js'; // 새로운 컴포넌트 import
import MiniMapToggle from './MiniMapToggle.js'; // 새로운 컴포넌트 import
import LabelTextSize from './LabelTextSize.js'; // 새로운 컴포넌트 import
import MapCoordinate from './MapCoordinate.js'; // MapCoordinate 컴포넌트 import
import ShowBorderToggleWithInput from './ShowBorderToggleWithInput.js'; // 새로운 컴포넌트 import

function BottomSection() {
    const [showGrid, setShowGrid] = useState(false);
    const [miniMapChecked, setMiniMapChecked] = useState(false);
    const [initialFontSize, setInitialFontSize] = useState(null);
    const [showBorder, setShowBorder] = useState(true);

    const handleFitClick = () => {
        const objectElement = document.getElementById('mainView');
        if (objectElement) {
            const svgDoc = objectElement.contentDocument;
            const rect = svgDoc?.getElementById('svg-pan-zoom-reset-pan-zoom');
            if (rect) {
                rect.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            }
        }
    };

    return (
        <>
            <section className='BottomSection'>
                <span>
                    <i>statistics group</i>
                    <select id="layerLevel">
                        <option value="1" disabled>world</option>
                        <option value="2" disabled>continent</option>
                        <option value="3" disabled>faction</option>
                        <option value="3" selected>country</option>
                        <option value="4" disabled>states</option>
                        <option value="5" disabled>province</option>
                        <option value="6" disabled>county</option>
                    </select>
                </span>

                <span>
                    <i>statistics type</i>
                    <select id="statisticsName">
                        <option value="1" selected>flag</option>
                        <option value="2">UN-ranking</option>
                        <option value="3" disabled>GDP</option>
                        <option value="4" disabled>GDP-growthRate(YoY)</option>
                        <option value="5" disabled>Population</option>
                        <option value="6" disabled>Pop-growthRate(YoY)</option>
                        <option value="7" disabled>Pop-density(YoY)</option>
                        <option value="8" disabled>creditRating</option>
                        <option value="9" disabled>covid-19</option>
                        <option value="10" disabled>technoIndex</option>
                    </select>
                </span>

                <span>
                    <i>layer Color</i>
                    <FillChange />
                </span>
                <span>
                    <i>label display Scope</i>
                    <select name={'label_Level'} id="label_Level">
                        <option value="1" selected>every</option>
                        <option value="2" disabled>world</option>
                        <option value="3" disabled>continent</option>
                        <option value="4" disabled>faction</option>
                        <option value="5" disabled>country</option>
                        <option value="6" disabled>states</option>
                        <option value="7" disabled>province</option>
                        <option value="8" disabled>county</option>
                    </select>
                </span>
                <span>
                    <i>label text value</i>
                    <select name={'label_text'} id="label_text">
                        <option value="1" selected>areaName</option>
                        <option value="2" disabled>areaID</option>
                        <option value="3" disabled>governor-Name</option>
                        <option value="4" disabled>govID</option>
                        <option value="5" disabled>statistics-Value</option>
                        <option value="6" disabled>none</option>
                    </select>
                </span>

                <LabelTextSize initialFontSize={initialFontSize} setInitialFontSize={setInitialFontSize} />

                <span>
                    <i>map Quality</i>
                    <select id="quality">
                        <option value="1" selected>low</option>
                        <option value="2" disabled>medium(premium)</option>
                        <option value="3" disabled>high(premium)</option>
                    </select>
                </span>
            </section>

            <section className='BottomSection2'>
                <span>
                    <input type={"checkbox"} id={'Series'} name={'Series'} />
                    <i>Fixing Series</i>&emsp;
                    <input type={"checkbox"} id={'Copy'} name={'Copy'} />
                    <i>Drag to Copy</i>&emsp;
                    <input type={"checkbox"} id={'Autoscaling'} name={'Autoscaling'} />
                    <i>Auto Scaling</i>&emsp;
                    <input type={"checkbox"} id={'closeup'} name={'closeup'} disabled />
                    <i>closeup Microstates</i>&emsp;|&emsp;Hide
                    <MiniMapToggle miniMapChecked={miniMapChecked} setMiniMapChecked={setMiniMapChecked} />
                    <GridToggle showGrid={showGrid} setShowGrid={setShowGrid} />
                    <input type={"checkbox"} id={'legend'} name={'legend'} disabled />
                    <i>legend</i>&emsp;|&emsp;Show
                    <ShowBorderToggleWithInput showBorder={showBorder} setShowBorder={setShowBorder} />
                    <input type={"checkbox"} id={'capital'} name={'capital'} disabled />
                    <i>★capital</i>
                    <input type={"checkbox"} id={'warZONE'} name={'warZONE'} disabled />
                    <i>⚔️conflict</i>
                    <input type={"checkbox"} id={'airport'} name={'airport'} disabled />
                    <i>🛫</i>
                    <input type={"checkbox"} id={'railRoad'} name={'railRoad'} disabled />
                    <i>🚂</i>
                    <input type={"checkbox"} id={'Seaport'} name={'Seaport'} disabled />
                    <i>⚓</i>
                </span>
            </section>
            <MapCoordinate />
        </>
    );
}

export { BottomSection };
