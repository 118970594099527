// src/data.js

export const countries = [
    'Algeria', 'Angola', 'Argentina', 'Australia', 'Austria', 'Azerbaijan',
    'Bahamas', 'Bahrain', 'Bangladesh', 'Belarus', 'Belgium', 'Bolivia',
    'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria',
    'Cameroon', 'Canada', 'Chile', 'China', 'Colombia', 'Costa Rica',
    'Côte d\'Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czechia',
    'Democratic Republic of the Congo', 'Denmark', 'Dominican Republic',
    'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Estonia',
    'Ethiopia', 'Finland', 'France', 'Gabon', 'Germany', 'Ghana', 'Greece',
    'Guatemala', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq',
    'Ireland', 'Israel', 'Italy', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya',
    'Kuwait', 'Latvia', 'Lebanon', 'Libya', 'Liechtenstein', 'Lithuania',
    'Luxembourg', 'Malaysia', 'Malta', 'Mauritius', 'México', 'Monaco',
    'Morocco', 'Myanmar', 'Nepal', 'Netherlands', 'New Zealand', 'Nigeria',
    'Norway', 'Oman', 'Pakistan', 'Panama', 'Papua New Guinea', 'Paraguay',
    'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia',
    'Saudi Arabia', 'Serbia', 'Singapore', 'Slovakia', 'Slovenia', 'South Africa',
    'South Korea', 'Spain', 'Sri Lanka', 'Sudan', 'Sweden', 'Switzerland', 'Thailand',
    'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Uganda', 'Ukraine',
    'United Arab Emirates', 'United Kingdom', 'Tanzania', 'United States', 'Uruguay',
    'Uzbekistan', 'Venezuela', 'Vietnam'
];

export const countryIds = [
    '1000068', '1000003', '1000009', '1000015', '1000016', '1000017',
    '1000026', '1000025', '1000023', '1000029', '1000019', '1000032',
    '1000027', '1000038', '1000033', '1000035', '1000024',
    '1000049', '1000040', '1000043', '1000044', '1000053', '1000056',
    '1000048', '1000102', '1000057', '1000061', '1000062',
    '1000050', '1000066', '1000067',
    '1000069', '1000070', '1000208', '1000092', '1000074',
    '1000075', '1000076', '1000079', '1000082', '1000063', '1000086', '1000093',
    '1000096', '1000104', '1000117', '1000107', '1000105', '1000115', '1000116',
    '1000114', '1000118', '1000119', '1000123', '1000122', '1000124', '1000125',
    '1000131', '1000142', '1000133', '1000135', '1000137', '1000140',
    '1000141', '1000164', '1000153', '1000162', '1000149', '1000145',
    '1000144', '1000154', '1000175', '1000173', '1000177', '1000170',
    '1000174', '1000178', '1000179', '1000181', '1000186', '1000191',
    '1000183', '1000184', '1000187', '1000190', '1000194', '1000196', '1000197',
    '1000199', '1000212', '1000202', '1000216', '1000217', '1000260',
    '1000130', '1000073', '1000138', '1000200', '1000218', '1000042', '1000226',
    '1000232', '1000233', '1000234', '1000229', '1000238', '1000239',
    '1000008', '1000083', '1000237', '1000242', '1000241',
    '1000243', '1000246', '1000249'
];

export const continents = [
    { name: 'Asia', id: '101' }, 
    { name: 'Europe', id: '102' }, 
    { name: 'Africa', id: '103' }, 
    { name: 'North America', id: '104' }, 
    { name: 'South America', id: '105' }, 
    { name: 'Oceania', id: '106' }
];

export const factions = [
    { name: 'Central America', id: '201' }, 
    { name: 'Middle East', id: '202' }, 
    { name: 'Southeast Asia', id: '203' }, 
    { name: 'India', id: '204' }, 
    { name: 'Central Asia', id: '205' }, 
    { name: 'Siberia', id: '206' }, 
    { name: 'North Africa', id: '207' },
    { name: 'North America (excluding Central America)', id: '208' },
    { name: 'East Asia (excluding Southeast Asia)', id: '209' },
    { name: 'Sub-Saharan Africa', id: '210' }
];

export const states = [
    { name: 'California', id: '301' }, 
    { name: 'Texas', id: '302' }, 
    { name: 'New York', id: '303' }, 
    { name: 'Florida', id: '304' }, 
    { name: 'Illinois', id: '305' }, 
    { name: 'Beijing', id: '306' }, 
    { name: 'Shanghai', id: '307' }, 
    { name: 'Guangdong', id: '308' }, 
    { name: 'Shandong', id: '309' }, 
    { name: 'Zhejiang', id: '310' }, 
    { name: 'Île-de-France', id: '311' }, 
    { name: 'Auvergne-Rhône-Alpes', id: '312' }, 
    { name: 'Nouvelle-Aquitaine', id: '313' }, 
    { name: 'Occitanie', id: '314' }, 
    { name: 'Brittany', id: '315' }, 
    { name: 'England', id: '316' }, 
    { name: 'Scotland', id: '317' }, 
    { name: 'Wales', id: '318' }, 
    { name: 'Northern Ireland', id: '319' }, 
    { name: 'Moscow', id: '320' }, 
    { name: 'Saint Petersburg', id: '321' }, 
    { name: 'Novosibirsk', id: '322' }, 
    { name: 'Yekaterinburg', id: '323' }, 
    { name: 'Nizhny Novgorod', id: '324' }
];
