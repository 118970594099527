import { useEffect, useState } from 'react';
import '../App.js';
import '../css/flagfigure.css';
import { countries, countryIds, continents, factions, states } from './data';

function FlagFigure() {
    const [selectedCategory, setSelectedCategory] = useState('country');
    const [selectedCountry, setSelectedCountry] = useState(null);

    // 선택된 카테고리에 따라 렌더링할 데이터 결정
    const getDataByCategory = (category) => {
        switch (category) {
            case 'country':
                return countries.map((name, index) => ({ name, id: countryIds[index] }));
            case 'continent':
                return continents;
            case 'faction':
                return factions;
            case 'states':
                return states;
            default:
                return [];
        }
    };

    const data = getDataByCategory(selectedCategory);

    // 선택된 카테고리 상태 변경
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    // 지도 뷰어 초기화 함수
    function checkAndInitThumbnailViewer() {
        console.log('restart');
        if (typeof window.thumbnailViewer === 'function' && document.getElementById('country-frame')) {
            window.zoomTiger = window.svgPanZoom('#country-frame', {
                zoomEnabled: true,
                controlIconsEnabled: true,
                fit: true,
                center: true,
            });
            document.getElementById('svg-pan-zoom-controls').setAttribute('style', 'display: none');
            document.getElementById('country-frame').addEventListener('mouseover', function() {
                document.getElementById('svg-pan-zoom-controls').setAttribute('style', 'display: block;');
            });
            document.getElementById('country-frame').addEventListener('mouseout', function() {
                document.getElementById('svg-pan-zoom-controls').setAttribute('style', 'display: none;');
            });
        } else {
            setTimeout(checkAndInitThumbnailViewer, 100);
            console.log('fail');
        }
    }

    // 링크에 첫 글자 강조 및 쉼표 추가
    useEffect(() => {
        const linksContainer = document.getElementById('links-container');
        const links = linksContainer.getElementsByTagName('a');
        const usedFirstLetters = new Set();

        Array.from(links).forEach((link, index) => {
            const text = link.textContent;
            const firstLetter = text[0];

            if (!usedFirstLetters.has(firstLetter)) {
                usedFirstLetters.add(firstLetter);
                const emphasizedSpan = document.createElement('span');
                emphasizedSpan.className = 'emphasized';
                emphasizedSpan.textContent = firstLetter;
                link.textContent = text.slice(1);
                link.insertBefore(emphasizedSpan, link.firstChild);
            }

            if (index < links.length - 1) {
                const comma = document.createElement('span');
                comma.textContent = ', ';
                link.appendChild(comma);
            }
        });
    }, [selectedCategory]);

    // 선택된 국가에 따라 지도 업데이트
    useEffect(() => {
        if (selectedCountry) {
            const objectElement = document.getElementById('mainView');
            const handleLoad = () => {
                const existingFrame = document.getElementById('country-frame');
                if (existingFrame) {
                    existingFrame.remove();
                }

                const svgDoc = objectElement.contentDocument;
                const svgElement = svgDoc.getElementById('worldmap');
                svgElement.style.visibility = 'hidden';
                svgElement.style.pointerEvents = 'none';
                const elements = svgElement.getElementById(selectedCountry);
                const bbox = elements.getBBox();

                const newSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                const aspectRatio = bbox.width / bbox.height;
                let newWidth, newHeight;

                if (window.innerWidth === window.screen.width && window.innerHeight === window.screen.height) {
                    newWidth = 100 * window.innerHeight / 100 * aspectRatio;
                    newHeight = 100 * window.innerHeight / 100;
                } else {
                    newWidth = 70 * window.innerHeight / 100 * aspectRatio;
                    newHeight = 70 * window.innerHeight / 100;
                    const maxWidth = window.innerWidth * 0.85 - window.innerHeight * 0.35;
                    if (newWidth > maxWidth) {
                        const scaleFactor = maxWidth / newWidth;
                        newWidth = maxWidth;
                        newHeight = newHeight * scaleFactor;
                    }
                }

                newSvg.removeAttribute('style');
                newSvg.setAttribute('id', 'country-frame');
                newSvg.setAttribute('width', `${newWidth}px`);
                newSvg.setAttribute('height', `${newHeight}px`);
                newSvg.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
                newSvg.setAttribute(
                    'style',
                    'position: fixed; top: 9vh; left:34vh; z-index: 1000; border: 2px solid black; background-color:#95d9fe; opacity: 0.8; transition: opacity 0.3s, border-color 0.3s;'
                );

                const handleEvent = (event) => {
                    if (event.target.tagName === 'path' || event.target.tagName === 'text') {
                        if (event.type === 'mouseover') {
                            newSvg.style.borderColor = 'red';
                            event.target.style.opacity = '0.6';
                            event.target.style.stroke = 'red';
                            event.target.style.strokeWidth = '0.1px';
                        } else if (event.type === 'mouseout') {
                            newSvg.style.borderColor = 'black';
                            event.target.style.stroke = 'none';
                            event.target.style.opacity = '1.0';
                        } else if (event.type === 'click') {
                            if (typeof window.fetchingmap === 'function') {
                                window.fetchingmap(event.target);
                            }
                        }
                    }
                };

                newSvg.addEventListener('mouseover', handleEvent);
                newSvg.addEventListener('mouseout', handleEvent);
                newSvg.addEventListener('click', handleEvent);

                const clone = elements.cloneNode(true);
                clone.removeAttribute('fill');
                clone.setAttribute('style', 'fill:darkblue;');
                newSvg.appendChild(clone);
                checkAndInitThumbnailViewer();

                document.body.appendChild(newSvg);
                svgElement.setAttribute('style', 'background-color:grey;');
                svgElement.style.visibility = 'hidden';
                svgElement.style.pointerEvents = 'none';

                let isDragging = false;
                let dragOffsetX = 0;
                let dragOffsetY = 0;

                newSvg.addEventListener('mousedown', (event) => {
                    if (event.target.tagName === 'rect') return;
                    if (event.target.tagName === 'svg') {
                        isDragging = true;
                        dragOffsetX = event.clientX - newSvg.getBoundingClientRect().left;
                        dragOffsetY = event.clientY - newSvg.getBoundingClientRect().top;
                        newSvg.style.cursor = 'move';
                    }
                });

                document.addEventListener('mousemove', (event) => {
                    if (isDragging) {
                        const newX = event.clientX - dragOffsetX;
                        const newY = event.clientY - dragOffsetY;
                        newSvg.style.left = `${newX}px`;
                        newSvg.style.top = `${newY}px`;
                    }
                });

                document.addEventListener('mouseup', () => {
                    isDragging = false;
                    newSvg.style.cursor = 'default';
                });
            };

            if (objectElement) {
                handleLoad();
            }
        }
    }, [selectedCountry]);

    // 전체 화면 변경 이벤트 리스너
    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                console.log('Exited fullscreen mode.');
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
        <>
            <div className="searchbox">
                <input type="search" id="site-search" name="q" placeholder='search your location'/><button>Search</button>
            </div>
            <nav>
                <details><summary><a>WORLD</a> ▷</summary></details>
                <article id='links-container'>
                    {data.map((item, index) => (
                        <a
                            key={item.name || item}
                            href={`#${item.id || item}`}
                            onClick={(e) => {
                                e.preventDefault();
                                if (window.innerWidth === window.screen.width && window.innerHeight === window.screen.height) {
                                } else {
                                }
                                setSelectedCountry(item.id || item);
                            }}>{item.name || item}
                        </a>
                    ))}
                </article>
                <div className="pageNum1">
                    <button 
                        onClick={() => handleCategoryChange('continent')} 
                        style={{ backgroundColor: selectedCategory === 'continent' ? 'lightblue' : '' }}
                    >continent</button>
                    <button 
                        onClick={() => handleCategoryChange('faction')} 
                        style={{ backgroundColor: selectedCategory === 'faction' ? 'lightblue' : '' }}
                    >faction</button>
                    <button 
                        onClick={() => handleCategoryChange('country')} 
                        style={{ backgroundColor: selectedCategory === 'country' ? 'lightblue' : '' }}
                    >country</button>
                </div>
                <div className="pageNum2">
                    <button 
                        onClick={() => handleCategoryChange('states')} 
                        style={{ backgroundColor: selectedCategory === 'states' ? 'lightblue' : '' }}
                    >states</button>
                    <button disabled>province</button>
                    <button disabled>county</button>
                </div>
                <div className="pageNum3">
                    <button disabled>historical</button>
                    <button disabled>diplomacy</button>
                    <button disabled>warfront</button>
                </div>
            </nav>
        </>
    );
}

export { FlagFigure };
