import React, { useEffect, useRef, useState } from 'react';
import '../css/MapCoordinate.css';

function MapCoordinate() {
    const [coordX, setCoordX] = useState(0);
    const [coordY, setCoordY] = useState(0);
    const [scale, setScale] = useState(1.2);

    const xRef = useRef(0);
    const yRef = useRef(0);
    const scaleRef = useRef(1.2);

    useEffect(() => {
        const updateCoordinates = setInterval(() => {
            setCoordX(Math.round(xRef.current));
            setCoordY(Math.round(yRef.current));
            setScale(Math.round(scaleRef.current * 100) / 100);
        }, 100);
        return () => clearInterval(updateCoordinates);
    }, []);

    useEffect(() => {
        const svgDocument = document.querySelector('#mainView').contentDocument;

        const handleMouseDown = () => (isDragging.current = true);
        const handleMouseUp = () => (isDragging.current = false);
        const handleMouseMove = () => isDragging.current && updateTransform();

        svgDocument.addEventListener('mousedown', handleMouseDown);
        svgDocument.addEventListener('mouseup', handleMouseUp);
        svgDocument.addEventListener('mousemove', handleMouseMove);
        svgDocument.addEventListener('wheel', updateTransform);
        svgDocument.addEventListener('scroll', updateTransform);

        return () => {
            svgDocument.removeEventListener('mousedown', handleMouseDown);
            svgDocument.removeEventListener('mouseup', handleMouseUp);
            svgDocument.removeEventListener('mousemove', handleMouseMove);
            svgDocument.removeEventListener('wheel', updateTransform);
            svgDocument.removeEventListener('scroll', updateTransform);
        };
    }, []);

    const isDragging = useRef(false);

    const updateTransform = () => {
        const transform = document.querySelector('#mainView').contentDocument.firstChild.childNodes[1].getAttribute('transform');
        if (transform) {
            const matrixValues = transform.match(/-?[\d\.]+/g).map(Number);
            if (matrixValues.length === 6) {
                xRef.current = matrixValues[4];
                yRef.current = matrixValues[5];
                scaleRef.current = matrixValues[0];
            }
        }
    };

    const handleFitClick = () => {
        const objectElement = document.getElementById('mainView');
        if (objectElement) {
            const svgDoc = objectElement.contentDocument;
            const resetButton = svgDoc?.getElementById('svg-pan-zoom-reset-pan-zoom');
            resetButton?.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        }
    };

    return (
        <div id="map-coordinate" className="map-coordinate">
            <button className="FIT" onClick={handleFitClick}>FIT</button>
            X=<span>{Math.abs(Math.round(coordX / scale))}</span>&emsp;
            Y=<span>{Math.abs(Math.round(coordY / scale))}</span>&emsp;
            S=<span id="scaleF">{scale}&emsp;</span>&emsp;
            WGS84&emsp;mapDATA &copy; GADM.ORG&emsp;
        </div>
    );
}

export default MapCoordinate;
