import React, { useState, useEffect } from 'react';
import '../css/RightSection.css';

export default function RightSection({country,president, flagClick, current, setCurrent}) {
    

		const [countryName, setCountryName]=useState('UN');
		useEffect(()=>
		{	
			aler();
		},[country]);

        const [imageSrc, setImageSrc] = useState('');

        const formatDateToYYYYMMDD = (date) => {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            return `${year}${month}${day}`;
        };
    
        useEffect(() => {
            
            const yyyymmdd = formatDateToYYYYMMDD(current);
            fetch(`./imageslink/${'portraits/'+country +'/'+ yyyymmdd}`)
                .then(response => response.text())
                .then(data => {                  
                   document.getElementById('portraits').src = data.trim();// Assuming the response has the imageSrc field
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                });
                
                fetch(`./imageslink/${'flag/'+country +'/'+ yyyymmdd}`)
                .then(response => response.text())
                .then(data => {                  
                   document.getElementById('flag').src = data.trim();// Assuming the response has the imageSrc field
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                });  
        }, [current]);

	function aler()
	{
		var target=document.querySelector('#mainView').contentDocument
		if(target.getElementById(country))
		{	
				if(target.tagName==="text")
				{
					setCountryName(target.getElementById(country).textContent);
				}
				else if(target.getElementById(country).tagName==="path")
				{
					if(target.getElementById('t'+country)!==null)
					{
						setCountryName(target.getElementById('t'+country).textContent);
					}
				}				
		}
		else if(country===1000000)
		{
			setCountryName('UN');
		}
	}
    const [prevClicked, setPrevClicked] = useState(null);

    // 페이지가 로드될 때 Statistics Overview를 클릭한 것처럼 초기화
    useEffect(() => {
        const statisticsOverview = document.querySelector('#statistics-overview');
        if (statisticsOverview) {
            statisticsOverview.click();
        }
    }, []);

    const handleClick = (event) => {
        const target = event.target;
        if (target.tagName.toLowerCase() === 'td') {
            const clickedTd = target;
            if (prevClicked !== clickedTd) {
                if (prevClicked) {
                    prevClicked.classList.remove('clicked');
                    prevClicked.style.backgroundColor = ''; // 이전에 클릭한 td의 배경색을 초기화
                }
                clickedTd.classList.add('clicked');
                clickedTd.style.backgroundColor = 'lightblue'; // 클릭된 td에 배경색을 적용
                setPrevClicked(clickedTd);
            }
        }
    };

    const handleHover = (event) => {
        const target = event.target;
        if (target.tagName.toLowerCase() === 'td' && target !== prevClicked) {
            target.classList.add('hovered'); // 호버 효과 적용
        }
    };

    const handleHoverOut = (event) => {
        const target = event.target;
        if (target.tagName.toLowerCase() === 'td' && target !== prevClicked) {
            target.classList.remove('hovered'); // 호버 효과 제거
        }
    };

    return (
        <section className="table-container">
            <table className="table" onClick={handleClick} onMouseEnter={handleHover} onMouseLeave={handleHoverOut}>
                <tbody>
                    <tr>
                        <td colSpan="20" id="statistics-overview">Statistics Overview</td>
                    </tr>
                    <tr>
                        <td colSpan="10" id="q2">Foreign Affairs</td>
                        <td colSpan="10" id="q3">Internal Affairs</td>
                    </tr>
                    <tr>
                        <td colSpan="8" id="q4">Legislature</td>
                        <td colSpan="5" id="q5">ARMY</td>
                        <td colSpan="7" id="q6">Judiciary</td>
                    </tr>
                </tbody>
            </table>
                <img id="hoverflag" className='hoverflag' alt='hoverflag' src={president} />
                <img id="flag" className='flag' alt='flag' src={president} />
                <img id="portraits" className='portraits' alt='portraits' src={'https://statesplan.com/images/portraits/UN_Secretary-General_9.jpg'} />
                
                    <span className='flag'><b>{countryName}({country})</b></span>
                    <span className='portraits'>portraits</span>
                               
        </section>
    );
}

export {RightSection};