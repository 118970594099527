import { useEffect, useState } from 'react';
import '../App.js';
import '../css/Header.css';

function Header({ onButtonSelect }) {
  const initialButtons = [
    { id: "G", parts: ["WW1", "(~1918.11.11)"], order: 1 },
    { id: "F", parts: ["INTERWAR", "(~39.08.31)"], order: 2 },
    { id: "E", parts: ["WW2", "(~45.09.02)"], order: 3 },
    { id: "D", parts: ["COLDWAR", "(~91.12.26)"], order: 4 },
    { id: "C", parts: ["MODERN", "(~2020.01.29)"], order: 5 },
    { id: "B", parts: ["CORONA", "(~23.05.05)"], order: 6 },
    { id: "A", parts: ["Nowadays", "(~Today)"], order: 7 },
    { id: "X", parts: ["EVERYDAY", "(~Alldays)"], order: 8 }
  ];

  const [buttons, setButtons] = useState(initialButtons);
  const [hovered, setHovered] = useState(false);

  const handleButtonClick = (clickedButtonId) => {
    setButtons(prevButtons => {
      const clickedButton = prevButtons.find(button => button.id === clickedButtonId);
      const remainingButtons = prevButtons.filter(button => button.id !== clickedButtonId);
      
      const sortedRemainingButtons = remainingButtons.sort((a, b) => a.order - b.order);
      return [...sortedRemainingButtons, clickedButton];
    });
    onButtonSelect(clickedButtonId);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <>
      <header onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div>
          {buttons.map((button, index) => (
            <button
              key={button.id}
              id={button.id}
              className={index === buttons.length - 1 ? 'active' : ''}
              onClick={() => handleButtonClick(button.id)}
              style={{ display: hovered || index === buttons.length - 1 ? 'block' : 'none' }}
            >
              {button.parts[0]} <br /> {button.parts[1]}
            </button>
          ))}
        </div>
      </header>
    </>
  );
}

export { Header };
