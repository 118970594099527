import { useEffect, useState, useRef  } from 'react';
import '../App.js';
import '../css/Timing.css';
import lang from '../lang.png';

function Timing({ configKey, current, setCurrent }) {
    function formatDate(date) {
        const months = ["January", "February", "March", "April", "May", "June", 
                        "July", "August", "September", "October", "November", "December"];
        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    }

    const dateConfigurations = {
        'X': { min: '1900-01-01', max: '2040-01-01', marks: ['1900-01-01', '1920-01-01', '1940-01-01', '1960-01-01', '1980-01-01', '2000-01-01', '2020-01-01', '2040-01-01'] },

        'A': { min: '2023-05-06', max: '2024-06-30', marks: ['2023-05-06', '2023-07-01', '2023-10-01', '2024-01-01', '2024-04-01', '2024-07-01'] },
        'B': { min: '2020-01-30', max: '2023-05-05', marks: ['2020-01-30', '2021-01-01', '2022-01-01', '2023-01-01', '2023-05-05'] },
        'C': { min: '1991-12-27', max: '2020-01-29', marks: ['1991-12-27', '1995-01-01', '2000-01-01', '2005-01-01', '2010-01-01', '2015-01-01', '2020-01-29'] },
        'D': { min: '1945-09-03', max: '1991-12-26', marks: ['1945-09-03', '1950-01-01', '1960-01-01', '1970-01-01', '1980-01-01', '1990-01-01', '1991-12-26'] },
        'E': { min: '1939-09-01', max: '1945-09-02', marks: ['1939-09-01', '1940-01-01', '1941-01-01', '1942-01-01', '1943-01-01', '1944-01-01', '1945-01-01', '1945-09-02'] },
        'F': { min: '1918-11-12', max: '1939-08-31', marks: ['1918-11-12', '1920-01-01', '1925-01-01', '1930-01-01', '1935-01-01', '1939-08-31'] },
        'G': { min: '1914-07-28', max: '1918-11-11', marks: ['1914-07-28', '1915-01-01', '1916-01-01', '1917-01-01', '1918-01-01', '1918-11-11'] }
      };
    const config = dateConfigurations[configKey]; 
    const [timerId, setTimerId] = useState(null);
    const minDate = new Date(config.min); // 최소 날짜 설정
    const maxDate = new Date(config.max); // 최대 날짜 설정
	const totalDays = (maxDate - minDate) / (86400000); 
	const containerRef = useRef(null);
	const calculatePosition = (date) => {
        const daysSinceStart = (date - minDate) / (86400000);
        return (daysSinceStart / totalDays) * 100; // Position in percentage
    };
	useEffect(() => {
        const container = containerRef.current;
        // Clear previous elements
        container.querySelectorAll('.date-label, .date-tick').forEach(elem => elem.remove());

        // Add marks for each year from 1950 to 2049
        config.marks.forEach(mark => {
            const date = new Date(mark);
            const position = calculatePosition(date);            
            const tick = document.createElement('div');
            tick.className = 'date-tick';
            tick.style.position = 'absolute';
            tick.style.left = `calc(${(position * 0.995)}% + 1px)`;
            tick.style.height = '100%';
            tick.style.width = '1px';
            tick.style.backgroundColor =  'black';
            tick.style.display = 'none';
            container.appendChild(tick);

            if (date) {
                const label = document.createElement('div');
                label.className = 'date-label';
                label.textContent = date.getFullYear().toString().slice(-2)+'.'+(date.getMonth()+1)+'.'+date.getDate();
                label.style.position = 'absolute';
                label.style.left = `${(position * 0.98)}%`;
                label.style.bottom = '-10%';
                label.style.fontSize = '0.5rem';                
                label.style.fontWeight = 'bold';
                label.style.whiteSpace = 'nowrap';
                container.appendChild(label);
            }
        }
    )}, [config]); 

    // 날짜 조정 함수
    const updateDate = (newDate) => {
        if (newDate < minDate) {
            setCurrent(minDate);
            clearInterval(timerId); // 범위를 벗어나면 타이머 정지
            
        } else if (newDate > maxDate) {
            setCurrent(maxDate);
            clearInterval(timerId); // 범위를 벗어나면 타이머 정지
        } else {
            setCurrent(newDate);
        }
    };

    const changeYear = (amount) => {
        if (timerId) clearInterval(timerId);
        const id = setInterval(() => {
            setCurrent(prev => {
                const newDate = new Date(prev.setFullYear(prev.getFullYear() + amount));
                if (newDate < minDate) {clearInterval(id); return minDate;}
                else if(newDate > maxDate){clearInterval(id); return maxDate;}
                else return newDate;
            });
        }, 500);
        setTimerId(id);
    };

    const changeMonth = (amount) => {
        if (timerId) clearInterval(timerId);
        const id = setInterval(() => {
            setCurrent(prev => {
                const newDate = new Date(prev.setMonth(prev.getMonth() + amount));
                if (newDate < minDate) {clearInterval(id); return minDate;}
                else if(newDate > maxDate){clearInterval(id); return maxDate;}
                else return newDate;
            });
        }, 500);
        setTimerId(id);
    };

    const changeDate = (amount) => {
        if (timerId) clearInterval(timerId);
        const id = setInterval(() => {
            setCurrent(prev => {
                const newDate = new Date(prev.setDate(prev.getDate() + amount));
                if (newDate < minDate) {clearInterval(id); return minDate;}
                else if(newDate > maxDate){clearInterval(id); return maxDate;}
                else return newDate;
            });
        }, 500);
        setTimerId(id);
    };

    const stopAutoChange = () => {
        if (timerId) clearInterval(timerId);
    };

    // 입력 필드에서 날짜 변경 처리
    const handleInputChange = (value, type) => {
        const newDate = new Date(current);
        if (type === 'year') {
            newDate.setFullYear(value);
        } else if (type === 'month') {
            newDate.setMonth(value - 1); // 입력은 1~12, Date는 0~11
        } else if (type === 'date') {
            newDate.setDate(value);
        }
        updateDate(newDate);
    };

    return (
        <>
            <div className="timer">
                <span className="big-range-container">
                <span className="date-range-container" ref={containerRef}>
                    <input
                        className="date-range-input"
                        type="range"
                        min="0"
                        max={(maxDate.getTime() - minDate.getTime()) / 86400000}
                        step="1"
                        value={(current.getTime() - minDate.getTime()) / 86400000}
                        onChange={(e) => updateDate(new Date(minDate.getTime() + parseInt(e.target.value) * 86400000))}
                    />
                </span>
                </span>
                <span className="header-text-container">
                    <input type="number" id="YYYY" className="header-text" value={current.getFullYear()} onChange={(e) => handleInputChange(e.target.value, 'year')} maxLength={4} min={1950} max={2050}/>
                    <input type="number" id="mm" className="header-text" value={current.getMonth() + 1} onChange={(e) => handleInputChange(e.target.value, 'month')} maxLength={2} min={1} max={12}/>
                    <input type="number" id="dd" className="header-text" value={current.getDate()} onChange={(e) => handleInputChange(e.target.value, 'date')} maxLength={2} min={1} max={31}/>
                </span>
                <a href="#" className="date-display">{formatDate(current)}</a>
                <span className="controls-container2">
                    <button onClick={() => changeYear(-1)} className="control-button2 icon2">
                        <span className="material-symbols-outlined">keyboard_double_arrow_left</span>
                    </button>
                    <button onClick={() => changeMonth(-1)} className="control-button2 icon2">
                        <span className="material-symbols-outlined">keyboard_arrow_left</span>
                    </button>
                    <button onClick={() => changeDate(-1)} className="control-button2 icon2">
                        <span className="material-symbols-outlined">arrow_left</span>
                    </button>
                    <button onClick={stopAutoChange} className="control-button2 icon2">
                        <span className="material-symbols-outlined">pause</span>
                    </button>
                    <button onClick={() => changeDate(1)} className="control-button2 icon2">
                        <span className="material-symbols-outlined">arrow_right</span>
                    </button>
                    <button onClick={() => changeMonth(1)} className="control-button2 icon2">
                        <span className="material-symbols-outlined">keyboard_arrow_right</span>
                    </button>
                    <button onClick={() => changeYear(1)} className="control-button2 icon2">
                        <span className="material-symbols-outlined">keyboard_double_arrow_right</span>
                    </button>
                </span>                 
            </div>
        </>
    );
}

export { Timing };
