import React, { useEffect, useState } from 'react';

const ShowBorderToggleWithInput = ({ showBorder, setShowBorder }) => {
    const [borderWidth, setBorderWidth] = useState(0.5);

    const updateStrokeWidth = (width) => {
        const objectElement = document.getElementById('mainView');
        if (objectElement && objectElement.contentDocument) {
            const svgDoc = objectElement.contentDocument;
            const pathElements = svgDoc.querySelectorAll('#layers path');
            pathElements.forEach((path) => {
                path.setAttribute('stroke-width', width);
                path.setAttribute('stroke', width > 0 ? '#000' : 'none'); // Ensure stroke is visible
                path.style.display = 'none';
                path.getBoundingClientRect(); // Trigger reflow
                path.style.display = '';
            });
        }
    };

    useEffect(() => {
        updateStrokeWidth(showBorder ? borderWidth : 0);
    }, [showBorder, borderWidth]);

    useEffect(() => {
        const objectElement = document.getElementById('mainView');
        const handleLoad = () => {
            updateStrokeWidth(showBorder ? borderWidth : 0);
        };

        if (objectElement) {
            objectElement.addEventListener('load', handleLoad);
            if (objectElement.contentDocument) {
                handleLoad();
            }
        }

        return () => {
            if (objectElement) {
                objectElement.removeEventListener('load', handleLoad);
            }
        };
    }, [borderWidth]);

    const handleCheckboxChange = () => {
        setShowBorder(!showBorder);
        if (!showBorder) {
            setBorderWidth(1); // Reset to default value when checked
        }
    };

    const handleNumberChange = (e) => {
        setBorderWidth(e.target.value);
    };

    return (
        <span>
            <input type="checkbox" id="showborder" name="showborder" checked={showBorder} onChange={handleCheckboxChange} />
            <i>▢border</i>
            {showBorder && (
                <input
                    type="number"
                    value={borderWidth}
                    onChange={handleNumberChange}
                    min="0"
                    step="0.1"
                    style={{ marginLeft: '10px', width: '40px' }}
                />
            )}
        </span>
    );
};

export default ShowBorderToggleWithInput;
